import { isClient } from '@vueuse/core';

import type { LocalStorageValue } from './local-storage';
import { LocalStorageKey } from './local-storage';

const isServer = !isClient;

const mocks = {
  setValue: (_: LocalStorageKey, __: LocalStorageValue) => {},
  getValue: (_: LocalStorageKey): LocalStorageValue | undefined => {
    return undefined;
  },
  remove: (_: LocalStorageKey) => {},
};

export function useLocalStorage() {
  /**
   * На сервере нет local-storage, поэтому возвращаем просто замоканные функции для удобства
   */
  if (isServer) {
    return mocks;
  }

  const remove = (key: LocalStorageKey): void => {
    try {
      window.localStorage.removeItem(key);
    } catch (err) {
      //
    }
  };

  const setValue = (key: LocalStorageKey, item: LocalStorageValue) => {
    try {
      const expires = Date.now() + (item.expires || 0) * 1000;
      const stringified = JSON.stringify({ ...item, expires: item.expires ? expires : undefined });

      window.localStorage.setItem(key, stringified);
    } catch (err) {
      //
    }
  };

  const getValue = (key: LocalStorageKey): LocalStorageValue | undefined => {
    try {
      const item = window.localStorage.getItem(key);

      if (!item) {
        return undefined;
      }

      const value: LocalStorageValue = JSON.parse(item);

      // Если поле не было установлено, значит считаем что ключ хранится "вечно"
      if (!value.expires) {
        return value;
      }

      const now = Date.now();

      // Значение устарело
      if (now > value.expires) {
        remove(key);
        return undefined;
      }

      return value;
    } catch (err) {
      return undefined;
    }
  };

  return {
    remove,
    setValue,
    getValue,
  };
}
